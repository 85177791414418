@media only screen and (max-width: 800px) {
  .about__page__mb {
    height: 90vh;
    margin-right: 0px;
    overflow-y: scroll;
    animation: animate__about ease-in-out 0.5s;
    -webkit-animation: animate__about ease-in-out 0.5s;
    -moz-animation: animate__about ease-in-out 0.5s;
  }
  .about__title {
    margin-left: 2rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: 1.5rem;
    letter-spacing: 5px;
    font-weight: 900;
    color: #ffffff;
    text-decoration: none;
  }
  .about__buttons {
    display: flex;
    flex-direction: column;
  }
  .crossImg {
    margin-top: 3rem;
  }
  .about__content {
    color: #ffffff;
    font-size: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 10rem;
    margin-top: 0;
  }

  .mb__button__ab {
    margin-bottom: 2rem;
    margin-left: 2rem;
    height: 60px;
    width: 250px;
    font-size: 1rem;
    font-weight: 600;
    color: black;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
