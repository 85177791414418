@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap%27");

.mobile {
  display: none;
}

.about__box {
  background-color: black;
  color: white;
  height: 100vh;
  margin-bottom: 12px;
  /* animation: transitionUp 0.9s; */
}

.about__heading > h1 {
  margin-left: 6rem;
}

.about__left {
  padding-top: 2.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  overflow-y: scroll;
  height: 70vh;
}

.content__active {
  animation: toggleTransitionIn ease-out 0.5s;
}
.content__deactive {
  animation: toggleTransitionOut ease-out 0.5s;
}

.content__active > p {
  font-family: "Open Sans", sans-serif !important;
}

.button {
  margin-top: 3.2rem;
  height: 60px;
  width: 200px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}
.button > a {
  color: black;
  text-decoration: none;
}

@keyframes toggleTransitionIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes toggleTransitionOut {
  from {
    opacity: 1;
    transform: translateX(0px);
  }
  to {
    opacity: 0;
    transform: translateX(-10px);
  }
}

@media only screen and (max-width: 800px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
