.mobile {
  display: none;
}
.work__box {
  background-color: black;
  color: white;
  height: 100vh;
  margin-bottom: 12px;
  /* animation: transitionUp 0.9s; */
}

.work__heading > h1 {
  /* margin-top: 2rem;
  font-size: 1.5rem;
  letter-spacing: 5px; */
}
.work__left {
  padding-left: 3rem;
  overflow-y: scroll;
  height: 80vh;
}
.work__left > h1 {
  margin-top: 2.5rem;
  cursor: pointer;
  font-size: 1.4rem;
}
.link {
  display: inline-block;
}
.link:hover {
  border-bottom: 2px solid red !important;
}
.link__active {
  border-bottom: 2px solid red !important;
}
.work__right {
  padding-right: 2rem;
  height: 70vh;
  overflow-y: scroll;
  margin-top: 2.5rem;
}
.work__right > p,
li {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 1000px) {
  .work__left > h1 {
    margin-top: 3rem;
    font-size: 1.2rem !important;
  }
}
@media only screen and (max-width: 800px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
