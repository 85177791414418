.mobile {
  display: none;
}
.services__box {
  background-color: black;
  color: white;
  height: 100vh;
  margin-bottom: 12px;
  /* animation: transitionUp 0.9s; */
}

.services__left {
  padding-left: 3rem;
}
.services__left > h1 {
  margin-top: 3.5rem;
  cursor: pointer;
  font-size: 1.3rem;
}
.link {
  display: inline-block;
}
.link:hover {
  border-bottom: 2px solid red !important;
}
.link__active {
  border-bottom: 2px solid red !important;
}
.services__right {
  padding-right: 2rem;
  height: 70vh;
  overflow-y: scroll;
  margin-top: 2.5rem;
}
.services__right > p,
li {
  font-weight: 400;
  margin-top: 1rem;
  font-size: 1.2rem;
}
@media only screen and (max-width: 1000px) {
  .services__left > h1 {
    margin-top: 3rem;
    font-size: 1.5rem !important;
  }
}
@media only screen and (max-width: 800px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
