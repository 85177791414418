@media only screen and (max-width: 800px) {
  .s_title {
    margin-left: 2rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: 1.5rem;
    letter-spacing: 5px;
    font-weight: bold;
    color: #ffffff;
    text-decoration: none;
  }
  .s_headings {
    display: flex;
    flex-direction: column;
  }

  .s_brand {
    transform-origin: top;
    transition: 0.7s ease;
    animation: fading__animate 0.5s ease 0s 1 forwards;
    -webkit-animation: fading__animate 0.5s ease 0s 1 forwards;
    -moz-animation: fading__animate 0.5s ease 0s 1 forwards;
  }
  .s_headings > h1 {
    color: #ffffff;
    margin-bottom: 2rem;
    font-size: 14pt;
  }
  .s_headings:last-child {
    margin-bottom: 10rem;
  }
  .heading__deactivate {
    display: none;
  }

  .menu {
    overflow-y: scroll;
    height: 90vh;
    margin-right: 2rem;
    margin-left: 2rem;
  }
}
