.mb__brand_box {
  color: white;
  font-size: 1rem;
  animation: fading__animate ease-in 0.7s;
  -webkit-animation: fading__animate ease-in 0.7s;
  -moz-animation: fading__animate ease-in 0.7s;
}
/* .mb__box {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.box__head {
  font-size: 14pt;
  text-transform: uppercase;
}
