@media only screen and (max-width: 800px) {
  .contact__title {
    margin-left: 2rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: 1.5rem;
    letter-spacing: 5px;
    font-weight: 900;
    color: #ffffff;
    text-decoration: none;
  }
  .crossImg {
    margin-top: 3rem;
  }

  /* .contact__buttons {
    margin-top: -2rem;
  } */

  .mb__button {
    margin-bottom: 1.25rem;
    margin-left: 2rem;
    height: 60px;
    width: 250px;
    font-size: 1rem;
    font-weight: 600;
    color: black;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .mb__button__toggle {
    display: none;
    margin-bottom: 1rem;
    margin-left: 1.5rem;
  }

  .mb__button__toggle > a {
    color: white;
    padding-left: 10px;
    margin-left: 33px;
  }
  .contact__buttons {
    display: flex;
    flex-direction: column;
  }

  .contact__content {
    color: #ffffff;
    margin-bottom: 10rem;
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .contact__content > h3 {
    font-size: 1.75rem;
  }
  .contact__content > a {
    margin-bottom: 2rem;
    display: block;
  }

  .contact__content > a {
    color: white;
    text-decoration: none;
  }
  .contact__form {
    margin: 0rem 2rem 1rem 2rem;
    display: none;
    flex-direction: column;
    max-width: 400px;
  }
  .contact__form > input {
    height: 50px;
    margin-bottom: 0.5rem;
    padding: 10px;
  }
  .datePicker {
    margin-bottom: 0.5rem;
    background-color: white;
  }
  .contact__form > button {
    margin-top: 10px;
  }
  .mbGetLoc {
    display: none;
    width: 270px;
    height: 270px;
    border: 0;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    padding: 10px;
  }
  .contact__activate {
    display: flex;
    animation: toggleTransitionDown 0.8s;
    -webkit-animation: toggleTransitionDown 0.8s;
    -moz-animation: toggleTransitionDown 0.8s;
  }

  @keyframes toggleTransitionDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
