@media only screen and (max-width: 720px) {
  .s_brand {
    color: white;
    /* margin-left: 2rem; */
    font-size: 1rem;
  }
  ul > li {
    font-size: 1.25rem;
    font-weight: lighter;
  }
  .brand__box {
    animation: fading__animate ease-in 0.35s;
    -webkit-animation: fading__animate ease-in 0.35s;
    -moz-animation: fading__animate ease-in 0.35s;
    margin-bottom: 1rem;
  }
  .box {
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .box__head {
    font-size: 14pt;
    text-transform: uppercase;
  }
  /* h1 {
    margin-bottom: 1rem;
  } */
  .work__right > p,
  li {
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.25rem;
  }
}
