@media only screen and (max-width: 800px) {
  .mbHome__box {
    height: 100vh;
    background: linear-gradient(to top, #000000 50%, #ffffff 50%);
    animation: home ease-in-out 1s;
    -webkit-animation: home ease-in-out 1s;
    -moz-animation: home ease-in-out 1s;
    animation: home ease-in-out 1s;
    margin: 0px !important;
  }
  .home_links {
    font-size: 14pt;
    font-weight: 700;
    margin-bottom: 0;
  }
  .mbHome__box > a {
    color: #000000;
    margin: 0px !important;
    text-decoration: none;
    font-size: 1.5rem;
    letter-spacing: 2px;
  }
  .mbHome__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  /* .mbHome__content > img {
    height: 115px;
  } */
}
