.mobile {
  display: none;
}
.social__box {
  height: 100vh;
  background: linear-gradient(to right, #000000 50%, #ffffff 50%);
  animation: transitionDown 0.7s;
}

.social__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
/* .social__content > embed {
  padding-left: 15rem;
  height: 25%;
} */
@media only screen and (max-width: 800px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
