.Photos__mb {
  margin-top: 0.5rem;
  /* min-width: 10rem; */
  position: relative;
  text-align: center;
}

.Photos__title__mb {
  font-size: 1rem;
}

.team__container__mb {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.animation_box {
  animation: fading__animate ease-in-out 1s;
  -webkit-animation: fading__animate ease-in-out 1s;
  -moz-animation: fading__animate ease-in-out 1s;
}
