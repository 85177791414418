@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap%27);
* {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: "Lato", sans-serif;
}

@-webkit-keyframes transitionUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(600px);
            transform: translateY(600px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes transitionUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(600px);
            transform: translateY(600px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes transitionDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-600px);
            transform: translateY(-600px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes transitionDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-600px);
            transform: translateY(-600px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
body {
  background-color: black;
  margin-right: 0;
  font-weight: lighter;
}

h1 {
  font-weight: 900;
  margin: 0;
}

h5 {
  margin: 0;
}

.heading > h1 {
  margin-left: 3rem;
}

.menu_heading {
  margin-top: 2rem;
  letter-spacing: 5px;
  font-size: 18pt;
}

.home_links {
  font-size: 18pt;
  font-weight: 900;
}

h1 {
  font-weight: 900;
  font-size: 38pt;
}

ul li:before {
  content: "\2022 ";
  color: white;
  padding-right: 0.5em;
}

.tabs {
  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
}
.tabs > a {
  font-size: 1.5rem;
  letter-spacing: 5px;
  font-weight: 900;
  color: #ffffff;
  text-decoration: none;
}

.back__logo {
  position: absolute;
  top: 20px;
  right: 20px;
}
.back__logo > a {
  color: white;
}

/* Scrollbar beautifying */
::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 20px;
  border: 8px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
@media only screen and (max-width: 1000px) {
  .tabs > a {
    font-size: 1.2rem !important;
  }
}

@media only screen and (max-width: 720px) {
  ::-webkit-scrollbar {
    width: 5px;
  }
  @-webkit-keyframes home {
    0% {
      -webkit-filter: blur(10px);
              filter: blur(10px);
      opacity: 0.2;
    }
    40% {
      -webkit-filter: blur(8px);
              filter: blur(8px);
      opacity: 0.6;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes home {
    0% {
      -webkit-filter: blur(10px);
              filter: blur(10px);
      opacity: 0.2;
    }
    40% {
      -webkit-filter: blur(8px);
              filter: blur(8px);
      opacity: 0.6;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @-webkit-keyframes animate__service {
    from {
      /* background-color: white; */
      -webkit-transform: translateX(-400px);
              transform: translateX(-400px);
      -webkit-filter: blur(4px);
              filter: blur(4px);
    }
    to {
      -webkit-filter: blur(0px);
              filter: blur(0px);
    }
  }
  @keyframes animate__service {
    from {
      /* background-color: white; */
      -webkit-transform: translateX(-400px);
              transform: translateX(-400px);
      -webkit-filter: blur(4px);
              filter: blur(4px);
    }
    to {
      -webkit-filter: blur(0px);
              filter: blur(0px);
    }
  }
  @-webkit-keyframes animate__work {
    from {
      /* background-color: white; */
      -webkit-transform: translateX(400px);
              transform: translateX(400px);
      -webkit-filter: blur(4px);
              filter: blur(4px);
    }
    to {
      -webkit-filter: blur(0px);
              filter: blur(0px);
    }
  }
  @keyframes animate__work {
    from {
      /* background-color: white; */
      -webkit-transform: translateX(400px);
              transform: translateX(400px);
      -webkit-filter: blur(4px);
              filter: blur(4px);
    }
    to {
      -webkit-filter: blur(0px);
              filter: blur(0px);
    }
  }

  @-webkit-keyframes animate__about {
    from {
      /* background-color: white; */
      -webkit-transform: translateX(400px);
              transform: translateX(400px);
      -webkit-filter: blur(4px);
              filter: blur(4px);
    }
    to {
      -webkit-filter: blur(0px);
              filter: blur(0px);
    }
  }

  @keyframes animate__about {
    from {
      /* background-color: white; */
      -webkit-transform: translateX(400px);
              transform: translateX(400px);
      -webkit-filter: blur(4px);
              filter: blur(4px);
    }
    to {
      -webkit-filter: blur(0px);
              filter: blur(0px);
    }
  }

  @-webkit-keyframes fading__animate {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-60px);
              transform: translateY(-60px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes fading__animate {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-60px);
              transform: translateY(-60px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes subTitles__animations__work {
    from {
      -webkit-transform: translateY(400px);
              transform: translateY(400px);
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes subTitles__animations__work {
    from {
      -webkit-transform: translateY(400px);
              transform: translateY(400px);
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.mobile {
  display: none;
}
.social__box {
  height: 100vh;
  background: linear-gradient(to right, #000000 50%, #ffffff 50%);
  -webkit-animation: transitionDown 0.7s;
          animation: transitionDown 0.7s;
}

.social__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
/* .social__content > embed {
  padding-left: 15rem;
  height: 25%;
} */
@media only screen and (max-width: 800px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

@media only screen and (max-width: 800px) {
  .mbHome__box {
    height: 100vh;
    background: linear-gradient(to top, #000000 50%, #ffffff 50%);
    animation: home ease-in-out 1s;
    -webkit-animation: home ease-in-out 1s;
    animation: home ease-in-out 1s;
    margin: 0px !important;
  }
  .home_links {
    font-size: 14pt;
    font-weight: 700;
    margin-bottom: 0;
  }
  .mbHome__box > a {
    color: #000000;
    margin: 0px !important;
    text-decoration: none;
    font-size: 1.5rem;
    letter-spacing: 2px;
  }
  .mbHome__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  /* .mbHome__content > img {
    height: 115px;
  } */
}

@media only screen and (max-width: 800px) {
  .s_title {
    margin-left: 2rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: 1.5rem;
    letter-spacing: 5px;
    font-weight: bold;
    color: #ffffff;
    text-decoration: none;
  }
  .s_headings {
    display: flex;
    flex-direction: column;
  }

  .s_brand {
    -webkit-transform-origin: top;
            transform-origin: top;
    transition: 0.7s ease;
    animation: fading__animate 0.5s ease 0s 1 forwards;
    -webkit-animation: fading__animate 0.5s ease 0s 1 forwards;
    -moz-animation: fading__animate 0.5s ease 0s 1 forwards;
  }
  .s_headings > h1 {
    color: #ffffff;
    margin-bottom: 2rem;
    font-size: 14pt;
  }
  .s_headings:last-child {
    margin-bottom: 10rem;
  }
  .heading__deactivate {
    display: none;
  }

  .menu {
    overflow-y: scroll;
    height: 90vh;
    margin-right: 2rem;
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 720px) {
  .s_brand {
    color: white;
    /* margin-left: 2rem; */
    font-size: 1rem;
  }
  ul > li {
    font-size: 1.25rem;
    font-weight: lighter;
  }
  .brand__box {
    animation: fading__animate ease-in 0.35s;
    -webkit-animation: fading__animate ease-in 0.35s;
    -moz-animation: fading__animate ease-in 0.35s;
    margin-bottom: 1rem;
  }
  .box {
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .box__head {
    font-size: 14pt;
    text-transform: uppercase;
  }
  /* h1 {
    margin-bottom: 1rem;
  } */
  .work__right > p,
  li {
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.25rem;
  }
}

.mobile {
  display: none;
}
.services__box {
  background-color: black;
  color: white;
  height: 100vh;
  margin-bottom: 12px;
  /* animation: transitionUp 0.9s; */
}

.services__left {
  padding-left: 3rem;
}
.services__left > h1 {
  margin-top: 3.5rem;
  cursor: pointer;
  font-size: 1.3rem;
}
.link {
  display: inline-block;
}
.link:hover {
  border-bottom: 2px solid red !important;
}
.link__active {
  border-bottom: 2px solid red !important;
}
.services__right {
  padding-right: 2rem;
  height: 70vh;
  overflow-y: scroll;
  margin-top: 2.5rem;
}
.services__right > p,
li {
  font-weight: 400;
  margin-top: 1rem;
  font-size: 1.2rem;
}
@media only screen and (max-width: 1000px) {
  .services__left > h1 {
    margin-top: 3rem;
    font-size: 1.5rem !important;
  }
}
@media only screen and (max-width: 800px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

@media only screen and (max-width: 800px) {
  .contact__title {
    margin-left: 2rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: 1.5rem;
    letter-spacing: 5px;
    font-weight: 900;
    color: #ffffff;
    text-decoration: none;
  }
  .crossImg {
    margin-top: 3rem;
  }

  /* .contact__buttons {
    margin-top: -2rem;
  } */

  .mb__button {
    margin-bottom: 1.25rem;
    margin-left: 2rem;
    height: 60px;
    width: 250px;
    font-size: 1rem;
    font-weight: 600;
    color: black;
    background-color: white;
    -webkit-appearance: none;
    appearance: none;
  }

  .mb__button__toggle {
    display: none;
    margin-bottom: 1rem;
    margin-left: 1.5rem;
  }

  .mb__button__toggle > a {
    color: white;
    padding-left: 10px;
    margin-left: 33px;
  }
  .contact__buttons {
    display: flex;
    flex-direction: column;
  }

  .contact__content {
    color: #ffffff;
    margin-bottom: 10rem;
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .contact__content > h3 {
    font-size: 1.75rem;
  }
  .contact__content > a {
    margin-bottom: 2rem;
    display: block;
  }

  .contact__content > a {
    color: white;
    text-decoration: none;
  }
  .contact__form {
    margin: 0rem 2rem 1rem 2rem;
    display: none;
    flex-direction: column;
    max-width: 400px;
  }
  .contact__form > input {
    height: 50px;
    margin-bottom: 0.5rem;
    padding: 10px;
  }
  .datePicker {
    margin-bottom: 0.5rem;
    background-color: white;
  }
  .contact__form > button {
    margin-top: 10px;
  }
  .mbGetLoc {
    display: none;
    width: 270px;
    height: 270px;
    border: 0;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    padding: 10px;
  }
  .contact__activate {
    display: flex;
    animation: toggleTransitionDown 0.8s;
    -webkit-animation: toggleTransitionDown 0.8s;
    -moz-animation: toggleTransitionDown 0.8s;
  }

  @-webkit-keyframes toggleTransitionDown {
    from {
      opacity: 0;
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  }

  @keyframes toggleTransitionDown {
    from {
      opacity: 0;
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  }
}

.mobile {
  display: none;
}

.contact__box {
  background-color: black;
  color: white;
  height: 100vh;
  margin-bottom: 12px;
  /* animation: transitionUp 0.9s; */
}

.contact__heading > h1 {
  margin-left: 5rem;
}

.contact__left {
  padding-top: 2.5rem;
  padding-left: 3rem;
  font-size: 1.5rem;
}
.contact__left__box {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.contact__left__box > a {
  margin-top: 2rem;
  color: white;
  text-decoration: none;
  font-weight: 600;
}
.contact__left__box > p {
  font-weight: 600;
  margin-top: 2rem;
}
.contact__left__box > p:nth-child(even) {
  font-size: 1.3rem;
}
.button {
  margin-top: 3.2rem;
  margin-left: 2rem;
  height: 60px;
  width: 200px;
  font-size: 1rem;
  font-weight: 600;
}
.button:hover {
  background-color: black;
  color: white;
  border: 1px solid white;
}
.button__toggle {
  display: none;
  margin-top: 1rem;
  margin-left: 1.5rem;
}
.button__toggle > a {
  color: white;
  padding-left: 10px;
  margin-left: 20px;
}
.button__toggle__active {
  display: flex;
}
.schedule__form {
  margin-top: 10px;
  display: none;
  flex-direction: column;
  max-width: 400px;
  margin-left: 2rem;
}
.schedule__form > input {
  height: 50px;
  margin-top: 10px;
  padding: 10px;
}
.schedule__form > button {
  margin-top: 10px;
}
.getLoc {
  display: none;
  border: 0;
  margin-top: 10px;
  margin-left: 1.5rem;
  padding: 10px;
  width: 20rem;
  height: 12rem;
}
.activate {
  display: flex;
  -webkit-animation: toggleTransitionDown 0.6s;
          animation: toggleTransitionDown 0.6s;
}

@-webkit-keyframes toggleTransitionDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes toggleTransitionDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@media only screen and (max-width: 800px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

.mb__brand_box {
  color: white;
  font-size: 1rem;
  animation: fading__animate ease-in 0.7s;
  -webkit-animation: fading__animate ease-in 0.7s;
  -moz-animation: fading__animate ease-in 0.7s;
}
/* .mb__box {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.box__head {
  font-size: 14pt;
  text-transform: uppercase;
}

.Card {
  margin-bottom: 2rem;
}

.card__content > a {
  text-decoration: none;
}

.mobile {
  display: none;
}
.work__box {
  background-color: black;
  color: white;
  height: 100vh;
  margin-bottom: 12px;
  /* animation: transitionUp 0.9s; */
}

.work__heading > h1 {
  /* margin-top: 2rem;
  font-size: 1.5rem;
  letter-spacing: 5px; */
}
.work__left {
  padding-left: 3rem;
  overflow-y: scroll;
  height: 80vh;
}
.work__left > h1 {
  margin-top: 2.5rem;
  cursor: pointer;
  font-size: 1.4rem;
}
.link {
  display: inline-block;
}
.link:hover {
  border-bottom: 2px solid red !important;
}
.link__active {
  border-bottom: 2px solid red !important;
}
.work__right {
  padding-right: 2rem;
  height: 70vh;
  overflow-y: scroll;
  margin-top: 2.5rem;
}
.work__right > p,
li {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 1000px) {
  .work__left > h1 {
    margin-top: 3rem;
    font-size: 1.2rem !important;
  }
}
@media only screen and (max-width: 800px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

@media only screen and (max-width: 800px) {
  .about__page__mb {
    height: 90vh;
    margin-right: 0px;
    overflow-y: scroll;
    animation: animate__about ease-in-out 0.5s;
    -webkit-animation: animate__about ease-in-out 0.5s;
    -moz-animation: animate__about ease-in-out 0.5s;
  }
  .about__title {
    margin-left: 2rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: 1.5rem;
    letter-spacing: 5px;
    font-weight: 900;
    color: #ffffff;
    text-decoration: none;
  }
  .about__buttons {
    display: flex;
    flex-direction: column;
  }
  .crossImg {
    margin-top: 3rem;
  }
  .about__content {
    color: #ffffff;
    font-size: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 10rem;
    margin-top: 0;
  }

  .mb__button__ab {
    margin-bottom: 2rem;
    margin-left: 2rem;
    height: 60px;
    width: 250px;
    font-size: 1rem;
    font-weight: 600;
    color: black;
    background-color: white;
    -webkit-appearance: none;
    appearance: none;
  }
}

.Photos__mb {
  margin-top: 0.5rem;
  /* min-width: 10rem; */
  position: relative;
  text-align: center;
}

.Photos__title__mb {
  font-size: 1rem;
}

.team__container__mb {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.animation_box {
  animation: fading__animate ease-in-out 1s;
  -webkit-animation: fading__animate ease-in-out 1s;
  -moz-animation: fading__animate ease-in-out 1s;
}

.client__content > embed {
  margin: 1rem;
}

.client > embed {
  margin: 10px;
  padding: 10px;
  height: 150px;
  width: 250px;
}

.mobile {
  display: none;
}

.about__box {
  background-color: black;
  color: white;
  height: 100vh;
  margin-bottom: 12px;
  /* animation: transitionUp 0.9s; */
}

.about__heading > h1 {
  margin-left: 6rem;
}

.about__left {
  padding-top: 2.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  overflow-y: scroll;
  height: 70vh;
}

.content__active {
  -webkit-animation: toggleTransitionIn ease-out 0.5s;
          animation: toggleTransitionIn ease-out 0.5s;
}
.content__deactive {
  -webkit-animation: toggleTransitionOut ease-out 0.5s;
          animation: toggleTransitionOut ease-out 0.5s;
}

.content__active > p {
  font-family: "Open Sans", sans-serif !important;
}

.button {
  margin-top: 3.2rem;
  height: 60px;
  width: 200px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}
.button > a {
  color: black;
  text-decoration: none;
}

@-webkit-keyframes toggleTransitionIn {
  from {
    opacity: 0;
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes toggleTransitionIn {
  from {
    opacity: 0;
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
@-webkit-keyframes toggleTransitionOut {
  from {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  to {
    opacity: 0;
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
}
@keyframes toggleTransitionOut {
  from {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  to {
    opacity: 0;
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
}

@media only screen and (max-width: 800px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

